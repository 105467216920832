import { ErrorMessage, Field, useFormikContext } from 'formik'
import { FieldsType, GroupsType, StepperType } from '../../../../../utils/stepper'
import {
  Button,
  CheckBox,
  DatePicker,
  Dropdown,
  Radio,
  NumberINR,
  TextField,
  Toggle,
  DisabledTextfield,
} from '../termsheet-builder/StepperSections'
import { TermsheetType } from '../TermsheetHelper'
import { selectCustomStyles } from '../../../../../utils/Select'
import { useEffect, useState } from 'react'
import { SearchCompanyResultType } from '../../../../../types/SearchCompanyResultType'
import {
  GetGlobalCompaniesQuery,
  useGetGlobalCompaniesQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { KTSVG } from '../../../../../_metronic/helpers'
import { MultiSelectOptionType } from '../../../../../utils/MultiSelect'
import CreatableSelect from 'react-select/creatable'
import { Tooltip } from 'react-tooltip'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

type Props = {
  sections: StepperType
  formik?: any
  aiInits?: any
}

type SheetNameType = {
  isEdit: boolean
  setTemplateName: any
  templateName: string
  setIsEdit: any
  documentType:string
}

type CompaniesType = {
  stepper: any
  setCompanyObj: any
  setCompanyName: any
  values: any
  isFromModelling?: boolean
  resetForm: any
  templateData: any
}

type FundnameType = {
  investorList: any
  values: any
  name?: string
}

type Options = {
  label: string
  value: string
}
type Investors = { 
  investorName: string 
  type:string
  investorId: number }

export function GetCompanies(props: CompaniesType) {
  const [companyOptions, setCompanyOptions] = useState<any>([])
  const [searchResults, setSearchResults] = useState<SearchCompanyResultType[]>([])
  const [showResults, setShowResults] = useState(false)
  const [showResetPopup, setShowResetPopup] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const navigate = useNavigate()
  const [nameOfCompany, setNameOfCompany] = useState<Options>({
    value: props.values.companyName,
    label: props.values.companyName,
  })
  const { data: globalCompanies, isFetched } = useGetGlobalCompaniesQuery<
    GetGlobalCompaniesQuery,
    Error
  >(graphqlRequestClient, {}, {})
  const [isQueryEnabled, setQueryEnabled] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const formik = useFormikContext<TermsheetType>()

  useEffect(() => {
    var options =
      globalCompanies?.globalCompaniesSearch &&
      globalCompanies?.globalCompaniesSearch.map((company: any) => {
        return {
          key: company.name,
          value: company.name,
          label: company.name,
          companyName: company.name,
          companyCin: company.cin,
          hissaCompanyId: company.id,
        }
      })
    setCompanyOptions(options)
  }, [isFetched])

  function handleSearchResults(data: SearchCompanyResultType[]) {
    setSearchResults(data)
    setShowResults(true)
    var options = data.map((company: any) => {
      return {
        key: company.name,
        value: company.name,
        label: company.name,
        companyName: company.name,
        companyCin: company.cin,
        hissaCompanyId: company.id,
      }
    })
    setCompanyOptions(options)
    console.log(options)
  }

  function HandleResetStepper() {
    setShowResetPopup(false)
    props.resetForm()
    formik.setFieldValue('isFromModelling', undefined)
    const state = {
      genericData: props.templateData,
      templateId: props.templateData?.id,
    }

    navigate('/deals/sendtermsheet', { state: state })
  }

  return (
    <div className='fv-row mb-6 ps-8'>
      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4 pb-4'>
          <label className='form-label required mb-3'>{'Company Name'}</label>
          <>
            <a data-tooltip-id='my-tooltip1' className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id='my-tooltip1' className=''>
              <div>If the company is yet to be incorporated please </div>
              <div>mention tentative company name/trade name</div>
            </Tooltip>
          </>
        </div>
        <div className='col-6 col-md-8'>
          <CreatableSelect
            className=''
            styles={selectCustomStyles}
            name='aria-company'
            inputId='aria-company-input'
            options={companyOptions}
            isClearable={true}
            onInputChange={(e) => {
              if (e.length >= 3) {
                setCompanyName(e)
                setQueryEnabled(true)
              }
            }}
            onChange={(e: any) => {
              if (e === null) {
                if (!props.isFromModelling) {
                  formik.setFieldValue('companyName', '')
                  formik.setFieldValue('cin', '')
                  formik.setFieldValue('hissaCompanyId', '')
                  setNameOfCompany({
                    value: '',
                    label: '',
                  })
                } else {
                  setShowResetPopup(true)
                }
              }
              if (props.isFromModelling) {
                setShowResetPopup(true)
              } else {
                var index = companyOptions.findIndex(
                  (company: any) => company.companyName === e?.companyName
                )
                if (index > -1) {
                  setSelectedItem(companyOptions[index])
                  props.setCompanyObj({
                    companyName: companyOptions[index].companyName,
                    companyId: companyOptions[index].companyCin,
                    hissaCompanyId: companyOptions[index].hissaCompanyId,
                  })
                  formik.setFieldValue('companyName', companyOptions[index].companyName)
                  formik.setFieldValue('cin', companyOptions[index].companyCin)
                  formik.setFieldValue('hissaCompanyId', companyOptions[index].hissaCompanyId)
                  props.setCompanyName(companyOptions[index].companyName)
                  setNameOfCompany({
                    value: companyOptions[index].companyName,
                    label: companyOptions[index].companyName,
                  })
                }
                if (index === -1 && e) {
                  setSelectedItem(e?.label)
                  props.setCompanyObj({
                    companyName: e?.label,
                    companyId: '',
                    hissaCompanyId: '',
                  })
                  formik.setFieldValue('companyName', e?.label)
                  formik.setFieldValue('cin', '')
                  formik.setFieldValue('hissaCompanyId', '')
                  props.setCompanyName(e?.label)
                  setNameOfCompany({
                    value: e?.value,
                    label: e?.label,
                  })
                }
                setShowResults(true)
              }
            }}
            placeholder='Enter company Name'
            formatCreateLabel={(inputText) => `Add "${inputText}"`}
            value={nameOfCompany}
          />
          {/* <Select
            className=''
            styles={selectCustomStyles}
            inputId='aria-company-input'
            name='aria-company'
            options={companyOptions}
            isClearable={true}
            onInputChange={(e) => {
              if (e.length >= 3) {
                setCompanyName(e)
                setQueryEnabled(true)
              }
            }}
            onChange={(e: any) => {
              var index = companyOptions.findIndex(
                (company: any) => company.companyName == e?.companyName
              )
              if (index > -1) {
                setSelectedItem(companyOptions[index])
                props.setCompanyObj({
                  companyName: companyOptions[index].companyName,
                  companyId: companyOptions[index].companyCin,
                  hissaCompanyId: companyOptions[index].hissaCompanyId,
                })
                formik.setFieldValue('companyName', companyOptions[index].companyName)
                formik.setFieldValue('cin', companyOptions[index].companyCin)
                formik.setFieldValue('hissaCompanyId', companyOptions[index].hissaCompanyId)
                props.setCompanyName(companyOptions[index].companyName)
                setNameOfCompany({
                  value: companyOptions[index].companyName,
                  label: companyOptions[index].companyName,
                })
              }
              setShowResults(true)
            }}
            value={nameOfCompany.label === '' ? 'Select...' : nameOfCompany}
          ></Select> */}
          <div className='text-danger mt-2'>
            <ErrorMessage name='companyName' />
          </div>
          {showResetPopup && (
            <Modal
              id='kt_modal_add_inv'
              tabIndex={-1}
              aria-hidden='true'
              dialogClassName='modal-dialog modal-dialog-centered mw-500px '
              show={showResetPopup}
              onHide={() => setShowResetPopup(false)}
            >
              <Modal.Body className='p-9'>
                <div className='card'>
                  <div className='card-body p-0'>
                    <h2 className='fw-bolder text-gray-heading pb-4'>Reset Values</h2>
                    <div className='fs-7 fw-normal pb-10'>
                      <b>Note : </b> This will remove all values that are filled from the model.
                    </div>

                    <div className='d-flex col justify-content-between pt-6'>
                      <button
                        className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                        onClick={() => setShowResetPopup(false)}
                      >
                        <span className='indicator-label'>Cancel</span>
                      </button>
                      <button
                        type='submit'
                        className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                        onClick={() => HandleResetStepper()}
                      >
                        {'Confirm'}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </div>
  )
}

export function GetInvestors(props: FundnameType) {
  const investorName: MultiSelectOptionType[] = []
  const invDetails:Investors[] =  JSON.parse(localStorage.getItem('userInvestors') || "[]")
  const formik = useFormikContext<TermsheetType>()
  const [nameOfInvestor, setNameOfInvestor] = useState<any>({
    value: props.values.investorId,
    label: props.values.investorName,
  })

  useEffect(() => {
    invDetails?.forEach((investor) =>
      investorName.push({
        value: investor?.investorId,
        label: investor?.investorName,
      })
    )
  }, [invDetails, investorName])
  return (
    <div className='fv-row mb-6 ps-8'>
      <div className='row align-items-baseline'>
        <div className='col-6 col-md-4 pb-4'>
          <label className='form-label required'>Select Investor/Fund</label>
        </div>
        <div className='col-6 col-md-8'>
          <CreatableSelect
            name='investorId'
            className=''
            styles={selectCustomStyles}
            inputId='aria-company-input'
            options={investorName}
            isClearable={true}
            onChange={(e: any) => {
              if (e === null) {
                setNameOfInvestor({ value: '', label: '' })
                formik.setFieldValue('investorId', '')
                formik.setFieldValue('investorName', '')
              } else {
                setNameOfInvestor(e)
                formik.setFieldValue('investorId', '0')
                formik.setFieldValue('investorName', e.label)
              }
            }}
            // placeholder='Select Investor/Fund'
            formatCreateLabel={(inputText) => `Add "${inputText}"`}
            value={nameOfInvestor}
          />
          {/* {props.name ? (
            <Select
              className=''
              options={investorName}
              name='investorId'
              onChange={(e: any) => {
                setNameOfInvestor(e)
                formik.setFieldValue('investorId', e.value)
                formik.setFieldValue('investorName', e.label)
              }}
              value={nameOfInvestor.label === '' ? 'Select...' : nameOfInvestor}
              styles={selectCustomStyles}
            />
          ) : (
            <>
              <Select
                className=''
                options={investorName}
                name='investorId'
                onChange={(e: any) => {
                  setNameOfInvestor(e)
                  formik.setFieldValue('investorId', e.value)
                  formik.setFieldValue('investorName', e.label)
                }}
                value={nameOfInvestor.label === '' ? 'Select...' : nameOfInvestor}
                styles={selectCustomStyles}
                placeholder='Select Investor/Fund'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='investorId' />
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  )
}

export function TermSheetName({documentType}: SheetNameType) {
  const formik = useFormikContext<TermsheetType>()
  const [templateName, setTemplateName] = useState<string>(documentType === 'Termsheet'? 
    'My Termsheet':
    documentType === 'NDA'?'Non-Disclosure Agreement':'My Document')
  return (
    <div className='fv-row mb-6 ps-8'>
      <div className='row align-items-baseline pt-8'>
        <div className='col-6 col-md-4 d-flex'>
          <label className='form-label required pt-3'>Document Name</label>
        </div>
        <div className='col-6 col-md-8'>
          <Field
            type={'text'}
            name={'termsheetName'}
            className='form-control form-control-md form-control-solid w-md-50'
            disabled={false}
            placeholder={'Enter Document Name'}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name={'termsheetName'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export function GenerateTermsheet(props: Props) {
  const formik = useFormikContext<TermsheetType>()
  const elements = (
    <div className='card mt-4 p-8 w-100'>
      <div className='row align-items-baseline'>
        <h3 className='col fw-bold pb-4'>{props.sections.stepname}</h3>
      </div>

      {props.sections.groups.map((group: GroupsType, index: number) => {
        return (
          <div className='row align-items-baseline pb-4' key={index}>
            {group.name !== '' && (
              <div className=''>
                <h4 className=' text-dark pt-8'>{group.name}</h4>
              </div>
            )}

            {group.fields.map((field: FieldsType) => {
              if (field.type.toLowerCase() === 'textfield') {
                if (
                  typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    : 'no'
                ) {
                  return (
                    <TextField
                      fieldName={field.name}
                      isBuilder={false}
                      variable={field.key}
                      type={field.value}
                      toolTip={field?.toolTip}
                      required={field.required}
                      aiInits={props.aiInits}
                    />
                  )
                } else <></>
              }

              if (field.type.toLowerCase() === 'numberinr') {
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    : formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <NumberINR
                      fieldName={field.name}
                      isBuilder={false}
                      variable={field.key || ''}
                      field={field}
                      toolTip={field?.toolTip}
                      required={field.required}
                      aiInits={props.aiInits}
                    />
                  )
                } else <></>
              }

              if (field.type.toLowerCase() === 'radio') {
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    : formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <Radio
                      fieldName={field.name}
                      isBuilder={false}
                      values={field.values ? field.values : []}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                    />
                  )
                } else <></>
              }

              if (field.type.toLowerCase() === 'checkbox') {
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    : formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <CheckBox
                      fieldName={field.name}
                      isBuilder={false}
                      values={field.values ? field.values : []}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                    />
                  )
                } else <></>
              }
              if (field.type.toLowerCase() === 'toggle') {
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    : formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <Toggle
                      fieldName={field.name}
                      isBuilder={false}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                    />
                  )
                } else <></>
              }

              if (field.type.toLowerCase() === 'disabledtextfield') {
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    :  formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <DisabledTextfield
                      fieldName={field.name}
                      isBuilder={true}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                      aiInits={props.aiInits}
                    />
                  )
                } else <></>
              }

              if (field.type.toLowerCase() === 'dropdown') {
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    : formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <Dropdown
                      fieldName={field.name}
                      isBuilder={false}
                      values={field.values ? field.values : []}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                      aiInits={props.aiInits}
                    />
                  )
                } else <></>
              }
              if (field.type.toLowerCase() === 'date')
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    :  formik.values[field.condition?.name]) ||'no'
                ) {
                  return (
                    <DatePicker
                      fieldName={field.name}
                      min={String(field.min)}
                      max={String(field.max)}
                      isBuilder={false}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                      aiInits={props.aiInits}
                    />
                  )
                } else <></>

              if (field.type.toLowerCase() === 'button')
                if (
                  !field.condition ||
                  (typeof formik.values[field.condition?.name] === 'boolean'
                    ? String(formik.values[field.condition?.name]) ===
                      String(field.condition?.value.toLowerCase())
                    : String(field.condition?.value.toLowerCase()) ===
                      String(formik.values[field.condition?.name])
                    ? 'yes'
                    :  formik.values[field.condition?.name])||'no'
                ) {
                  return (
                    <Button
                      fieldName={field.name}
                      isBuilder={false}
                      variable={field.key}
                      toolTip={field?.toolTip}
                      required={field.required}
                    />
                  )
                } else <></>
            })}
          </div>
        )
      })}
    </div>
  )
  return elements
}

export function DraftNotes(props: any) {
  const notes: any[] = props.jsonData[0]?.draftNotes
  return (
    <div className='fv-row ps-8'>
      <div className='row align-items-baseline'>
        {notes?.map((note: any) => (
          <>
            <h3 className='col fw-bold pb-2'>{note.title}</h3>
            <div>{note.description}</div>
          </>
        ))}
      </div>
    </div>
  )
}

export function RecommendedFields(props: any) {
  const groupedArray = props?.fields.reduce((acc: any, field: any) => {
    if (!acc[field.stepName]) {
      acc[field.stepName] = []
    }
    acc[field.stepName].push(field)
    return acc
  }, {})

  return (
    <div className='fv-row mb-6 ps-8'>
      {props?.fields.length > 0 && (
        <div className='row align-items-baseline'>
          <div className=''>The following recommended fields were not filled in:</div>
          <div className='p-3'>
            {Object.keys(groupedArray).map((stepName) => (
              <div key={stepName}>
                <h3 className='fw-bold'>{stepName}</h3>
                <ol>
                  {groupedArray[stepName].map((field: any) => (
                    <li key={field.name}>
                      <div>{field.name}</div>
                    </li>
                  ))}
                </ol>
              </div>
            ))}
          </div>
          <div>
            The document will be generated without filling these fields. You can come back and fill
            it and generate the document again.
          </div>
        </div>
      )}
    </div>
  )
}
