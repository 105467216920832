import Select from 'react-select'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { ChangeEvent, useState } from 'react'
import { selectCustomStyles } from '../../../utils/Select'
import { SelectOptionType } from '../../../utils/MultiSelect'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import {
  AddReportsToCompanyMutation,
  AddReportsToCompanyMutationVariables,
  CompanyObject,
  CompanyTypes,
  useAddReportsToCompanyMutation,
  useEditReportsOfCompanyMutation,
  useGetCompanyLatestReportQuery,
  useGetCompanyMisReportsQuery,
} from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'
import { hexToRGB } from '../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { downloadS3File } from '../../../utils/ExportExcel'
import { InputBox } from '../../../utils/NumberInputBox'

export interface MisReportType {
  name: string
  date?: string
  report: string
  tags: string[]
}

type Props = {
  companyData: CompanyObject
  closeModal: () => void
  isEdit: boolean
  reportData?: MisReportType
  reportId?: number
}

export function AddReportsForm(props: Props) {
  const [loading, setLoading] = useState(false)
  const [inputText, setInputText] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [fileError, setFileError] = useState<boolean>(false)
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('')
  const [isFile, setIsFile] = useState<string>(props.reportData?.report || '')
  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]

  const tagsObjList: { value?: string; label?: string }[] = []
  const tagsList = props.reportData?.tags
  tagsList?.map((tag: string) => tagsObjList.push({ value: tag, label: tag }))
  const [customTag, setCustomTag] = useState<boolean>(false)
  const [badgeList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
    { value: 'Quarter 1', label: 'Quarter 1' },
    { value: 'Quarter 2', label: 'Quarter 2' },
  ])
  const [values, setValues] = useState<any>(tagsObjList)

  const initValues: MisReportType = props.isEdit
    ? {
        name: props.reportData?.name || '',
        date: props.reportData?.date?.split('T')[0] || '',
        report: props.reportData?.report || '',
        // report: '',
        tags: props.reportData?.tags || [],
      }
    : {
        name: '',
        date: '',
        report: '',
        tags: [],
      }

  const misReportSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a Report name')
      .min(3, 'Report Name cannot be less than 3 characters')
      .label('Report Name'),
    date: Yup.string().required().label('Report Date'),
    report: Yup.string().required().label('Report File'),
  })

  const formik = useFormik<MisReportType>({
    initialValues: initValues,
    validationSchema: misReportSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (finalValues) => {
      setLoading(true)
      const tags: string[] = []
      values.map((element: any) => tags.push(element.value))
      props.isEdit
        ? editReportMutation.mutate({
            input: {
              id: Number(props.reportId),
              name: finalValues.name,
              companyId: props.companyData?.id,
              companyName: props.companyData?.name,
              companyType: props.companyData?.type,
              companyCurrency: props.companyData?.companyCurrency,
              companyCurrentSharePrice: props.companyData?.sharePrice as number,
              currency: props.companyData?.companyCurrency,
              jurisdiction: props.companyData?.jurisdiction as string,
              hissaCompanyId: props.companyData?.hissaCompanyId,
              reportDate: new Date(finalValues.date as string).toISOString().split('T')[0],
              file: file,
              tags: tags,
            },
          })
        : addReportMutation.mutate({
            input: {
              name: finalValues.name,
              companyId: props.companyData?.id,
              companyName: props.companyData?.name,
              companyType: props.companyData?.type,
              companyCurrency: props.companyData?.companyCurrency,
              companyCurrentSharePrice: props.companyData?.sharePrice as number,
              currency: props.companyData?.companyCurrency,
              jurisdiction: props.companyData?.jurisdiction as string,
              hissaCompanyId: props.companyData?.hissaCompanyId,
              reportDate: new Date(finalValues.date as string).toISOString().split('T')[0],
              file: file,
              tags: tags,
            },
          })
    },
  })

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      values.forEach((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
        } else {
          setDisable(false)
          setErrorMessage('')
        }
      })
    }
  }

  const changeHandler = async (event: any) => {
    const allowedTypes = ['xlsx']
    const filename = event.target.files[0].name
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
    if (!allowedTypes.includes(extension)) {
      setFileError(true)
      formik.setFieldValue('report', '')
      setFileErrorMessage('File must be Excel Document')
      setFile(undefined)
    } else if (event.target.files[0].size > 100e6) {
      setFileError(true)
      setFileErrorMessage('File must be lesser than 100 MB')
      setFile(undefined)
    } else if (event && event.target.files.length > 0 && event.target.files[0].size < 100e6) {
      setFile(event.target.files[0])
      formik.setFieldValue('report', event.target.value)
      setFileError(false)
      setFileErrorMessage('')
    }
  }

  const handleChange = (e: any) => {
    setValues(e)

    e?.forEach((ele: any) => {
      if (ele.value === 'Add New') {
        setValues((values: any) => {
          return values?.filter((element: any) => element?.value !== 'Add New')
        })
        setCustomTag((customTag) => {
          return !customTag
        })
      }
    })
  }

  const addReportMutation = useAddReportsToCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: () => {
        const compKey = {
          input: {
            id: Number(props.companyData?.id) || 0,
            type: props.companyData?.type,
            hissaCompanyId: props.companyData?.hissaCompanyId || props.companyData?.id || '',
          },
        }
        queryClient.invalidateQueries(useGetCompanyMisReportsQuery.getKey(compKey))
        queryClient.invalidateQueries(useGetCompanyLatestReportQuery.getKey(compKey))
        Toast('Report Uploaded Successfully!', ToastType.success)
        setLoading(false)
        props.closeModal()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setLoading(false)
        props.closeModal()
      },
    },
    {}
  )

  const editReportMutation = useEditReportsOfCompanyMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: () => {
        const compKey = {
          input: {
            id: Number(props.companyData?.id) || 0,
            type: props.companyData?.type,
            hissaCompanyId: props.companyData?.hissaCompanyId || props.companyData?.id || '',
          },
        }
        queryClient.invalidateQueries(useGetCompanyLatestReportQuery.getKey(compKey))
        queryClient.invalidateQueries(useGetCompanyMisReportsQuery.getKey(compKey))
        Toast('Report Updated Successfully!', ToastType.success)
        props.closeModal()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        props.closeModal()
      },
    },
    {}
  )

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form card w-100 p-0'>
      <div className='w-100 mx-auto'>
        <div className='row'>
          <div className='fv-row mb-6 col-6 col-md-4'>
            <label className='form-label required'>Report Name</label>

            <InputBox
              isDisabled={false}
              name={'name'}
              handleChange={(value: string) => {
                formik.setFieldValue('name', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('name', value)
              }}
              placeholder='Enter Report Name'
              currency={''}
              value={formik.values.name}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-6 col-md-4'>
            <label className='form-label required'>Report File</label>
            <div>
              {isFile === '' ? (
                <>
                  <div className='input-group'>
                    <input
                      type='file'
                      className='form-control custom-input fw-bold shadow-lg h-38px py-2 text-primary'
                      accept='.xlsx'
                      {...formik.getFieldProps('report')}
                      onChange={(e) => {
                        changeHandler(e)
                      }}
                    />
                    {formik.values.report !== '' && (
                      <button
                        className='btn btn-sm btn-outline'
                        type='button'
                        onClick={() => {
                          formik.setFieldValue('report', '')
                          setFileError(false)
                          setFile(undefined)
                          setFileErrorMessage('')
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs012.svg'
                          className='svg-icon-1 svg-icon-gray-500'
                        />
                      </button>
                    )}
                  </div>
                  <div className='text-danger mt-2'>
                    {fileError && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block fs-7'>{fileErrorMessage}</div>
                      </div>
                    )}
                    {formik.touched.report && formik.errors.report && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block fs-7'>{formik.errors.report}</div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <span
                  className={`badge fs-9 fw-semibold mb-1 me-2 p-2`}
                  style={{
                    background: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 0.2),
                    color: hexToRGB(getCSSVariableValue('--kt-donut-' + (3 % 10)), 1),
                  }}
                >
                  {props.reportData?.report}
                  <div
                    onClick={() => {
                      setIsFile('')
                      formik.setFieldValue('report', '')
                      formik.setFieldTouched('report', true)
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-gray-header ps-2'
                      path='/media/icons/duotune/general/gen040.svg'
                    />
                  </div>
                </span>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-6 col-md-4'>
            <label className='form-label'>Tags</label>
            <div className=''>
              {customTag === false ? (
                <Select
                  className='form-control-solid'
                  options={badgeList}
                  placeholder={'--Select--'}
                  value={values}
                  isMulti={true}
                  onChange={(e: any) => handleChange(e)}
                  styles={selectCustomStyles}
                />
              ) : (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      className='form-control form-control-solid me-2'
                      placeholder='Enter Tag Name'
                      onChange={(e) => handleChangeValue(e)}
                    />
                    {errorMessage && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block fs-7'>{errorMessage}</div>
                      </div>
                    )}
                    {}
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    disabled={disable}
                    onClick={(e) => {
                      setValues((values: any) => [
                        ...values,
                        { value: inputText, label: inputText },
                      ])
                      setCustomTag(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr012.svg'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setCustomTag(false)
                      setErrorMessage('')
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='fv-row mb-6 col-6 col-md-4'>
            <label className='form-label required'>Report Date</label>

            <input
              type='date'
              className='form-control custom-input fw-bold shadow-lg h-38px'
              placeholder='Enter Report Date'
              max={maxDate}
              {...formik.getFieldProps('date')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.date && formik.errors.date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.date}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <p className='form-label pt-1 text-start mb-0 '>
            Download the excel template
            <img
              src={toAbsoluteUrl('/media/svg/files/excel.svg')}
              alt=''
              className='fs-2 fw-bold p-0 ms-2 text-truncate cursor-pointer'
              style={{ maxWidth: '38px', maxHeight: '38px' }}
              onClick={() => {
                downloadS3File('https://equion-dev.s3.us-west-2.amazonaws.com/MIS-sample.xlsx')
              }}
            />
          </p>
        </div>
        <div className='d-flex justify-content-between pt-15'>
          <button className='btn btn-lg btn-secondary me-3' onClick={props.closeModal}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={loading || addReportMutation.isLoading}
          >
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}
