import Nouislider from 'nouislider-react'
import { useEffect, useState } from 'react'
import { kFormatter, kUnformatterNumber } from '../../../../../utils/CurrencyFormatter'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'
import { MultiSelectOptionType } from '../../../../../utils/MultiSelect'
import { TermsheetType } from '../TermsheetHelper'
import { SearchCompanyResultType } from '../../../../../types/SearchCompanyResultType'
import { SearchQuery, useSearchQuery } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { FieldsType } from '../../../../../utils/stepper'
import { numToWordsDeals } from '../../../../../utils/NumToWords'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import { Tooltip } from 'react-tooltip'
import { dateToFieldDate } from '../../../../../utils/FormatDate'

export type Prop = {
  fieldName: string
  isBuilder: boolean
  values?: any[]
  min?: string
  max?: string
  variable?: string
  field?: FieldsType
  type?: string
  toolTip?: any[]
  required: string
  aiInits?: any
}
type Dropdown = {
  label: string
  value: string | number
}

export const tooltipFns = {
  to(value: number) {
    return kFormatter(value)
  },
  from(value: string) {
    return kUnformatterNumber(value)
  },
}

function getDate(element: string) {
  let [day, month, year] = element.split('/')
  const dateObj = new Date(+year, +month - 1, +day + 1)
  return dateObj.toISOString().split('T')[0]
}

function getAiIcon() {
  return <KTSVG path='/media/icons/duotune/general/stars.svg' className='svg-icon-1' />
}

export function TextField(props: Prop) {
  return (
    <div className='row align-items-baseline pb-4'>
      <div className='col-6 col-md-4 pb-4 d-flex'>
        <label className={`form-label pt-3 ${props.required === 'true' ? 'required' : ''}`}>
          {props.fieldName}
        </label>
        {props.toolTip && (
          <>
            <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id={props.fieldName} className=''>
              {props.toolTip.map((tool: any) => (
                <>
                  <h1 className='bold text-white'>{tool.title}</h1>
                  <div>{tool.description}</div>
                </>
              ))}
            </Tooltip>
          </>
        )}
      </div>
      <div className='col-5'>
        <Field
          type={props.type || 'text'}
          name={props.variable}
          className='form-control custom-input fw-bold shadow-lg h-38px'
          disabled={props.isBuilder}
          placeholder={!props.isBuilder ? `Enter ${props.fieldName}` : ''}
          onWheel={(e: any) => e.target.blur()}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={props.variable || ''} />
        </div>
      </div>
      {props.aiInits &&
        props.aiInits[props.variable!] !== '' &&
        props.aiInits[props.variable!] !== 0 &&
        props.aiInits[props.variable!] !== '0.00' &&
        props.aiInits[props.variable!] !== undefined && (
          <div className='col-1'>
            <span>{getAiIcon()}</span>
          </div>
        )}
    </div>
  )
}

export function NumberINR(props: Prop) {
  const formik = useFormikContext<TermsheetType>()
  const [number, setNumber] = useState<number>(formik.values[props.variable || 0])
  const [toolTip, setToolTip] = useState<string>('US')
  const [moneyInWords, setMoneyInWords] = useState<string>(
    numToWordsDeals(0, formik.values.currency?.split('-')[1] || 'IN')
  )
  const [roundSizeSliderRef, setRoundSizeSliderRef] = useState<any>(null)
  useEffect(() => {
    setMoneyInWords(numToWordsDeals(number, formik.values.currency?.split('-')[1] || 'IN'))
    setToolTip(formik.values.currency?.split('-')[1] || 'IN')
  }, [formik.values.currency, number, toolTip])
  const toolTipFns = {
    to(value: number) {
      if (value === 0) {
        return value
      } else {
        return value.toFixed(2) //kFormatterUSD(value, toolTip)
      }
    },
    from(value: string) {
      return kUnformatterNumber(value)
    },
  }

  return (
    <div>
      <div className='col fv-md-row'>
        <div className='col md-row d-md-flex align-items-baseline'>
          {props.fieldName !== '' && (
            <div className='col-6 col-md-4 d-flex'>
              <label className={`form-label pt-3 ${props.required === 'true' ? 'required' : ''}`}>
                {props.fieldName}
              </label>
              {props.toolTip && (
                <>
                  <a
                    data-tooltip-id={props.fieldName}
                    data-tooltip-place={'bottom'}
                    className='ms-2 mt-2'
                  >
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
                  </a>
                  <Tooltip id={props.fieldName} className='' key='top'>
                    {props.toolTip.map((tool: any) => (
                      <>
                        <h1 className='bold text-white'>{tool.title}</h1>
                        <div>{tool.description}</div>
                      </>
                    ))}
                  </Tooltip>
                </>
              )}
            </div>
          )}
          <div className='col-12 col-md-3'>
            <Nouislider
              instanceRef={(instance) => {
                if (instance && !roundSizeSliderRef) {
                  setRoundSizeSliderRef(instance)
                }
              }}
              tooltips={[toolTipFns]}
              disabled={props.isBuilder}
              start={Number(formik.values[props.variable || '']) || 0}
              range={{
                min: Number(props.field?.min) || 0,
                max: Object.keys(formik.values).includes(String(props.field?.max))
                  ? Number(formik.values[props.field?.max || '']) !== 0
                    ? Number(formik.values[props.field?.max || ''])
                    : 20000
                  : Number(props.field?.max) || 10000000000,
              }}
              connect
              onChange={(e) => {
                setToolTip(formik.values.currency?.split('-')[1] || 'IN')
                formik.setFieldValue(props.variable || '', e.at(0))
                setNumber(e.at(0))
                setMoneyInWords(
                  numToWordsDeals(e.at(0), formik.values.currency?.split('-')[1] || 'IN')
                )
                formik.setFieldValue(
                  `${props.variable}Words` || '',
                  numToWordsDeals(e.at(0), formik.values.currency?.split('-')[1] || 'IN')
                )
              }}
              className=''
            />
          </div>
          <div className='col-12 col-md-4 pt-6 pt-md-0 ps-md-6 me-2 '>
            <Field
              type='text'
              className='form-control custom-input fw-bold shadow-lg h-38px'
              name={props.variable}
              disabled={props.isBuilder}
              onChange={(e: any) => {
                formik.handleChange(e)
                const values = parseInt(e.target.value?.replaceAll(',', '') || 0, 10)
                formik.setFieldValue(props.variable || '', Math.round(values))
                roundSizeSliderRef?.noUiSlider.set(values)
                setNumber(values)
                setMoneyInWords(
                  numToWordsDeals(values, formik.values.currency?.split('-')[1] || 'IN')
                )
                formik.setFieldValue(
                  `${props.variable}Words` || '',
                  numToWordsDeals(values, formik.values.currency?.split('-')[1] || 'IN')
                )
              }}
              value={Intl.NumberFormat(`${formik?.values?.currency || 'en-IN'}`).format(
                formik.values[props.variable || '']
              )}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') e.preventDefault()
              }}
            />
            <div className='form-text'>{moneyInWords}</div>
            <div className='text-danger mt-2'>
              <ErrorMessage name={props.variable || ''} />
            </div>
          </div>
          {props.aiInits &&
            props.aiInits[props.variable!] !== '' &&
            props.aiInits[props.variable!] !== 0 &&
            props.aiInits[props.variable!] !== '0.00' &&
            props.aiInits[props.variable!] !== undefined && (
              <div className='col-1'>
                <span>{getAiIcon()}</span>
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export function Radio(props: Prop) {
  const formik = useFormikContext<TermsheetType>()
  const [value, setValue] = useState<string>(' ')
  useEffect(() => {
    if (formik?.values?.founderShareRestrictions !== undefined) {
      if (value.length > 1) {
        const percentage = Math.round(100 / Number(value.split(' ')[0]))
        formik.setFieldValue('founderShareRestrictionsPercentage', percentage)
      }
    }
  }, [formik?.values?.founderShareRestrictions, value])
  return (
    <div className='row align-items-center pb-4'>
      <div className='col-6 col-md-4 pb-4 d-flex'>
        <h4 className='form-label pt-3'>{props.fieldName}</h4>
        {props.toolTip && (
          <>
            <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id={props.fieldName} className=''>
              {props.toolTip.map((tool: any) => (
                <>
                  <h1 className='bold text-white'>{tool.title}</h1>
                  <div>{tool.description}</div>
                </>
              ))}
            </Tooltip>
          </>
        )}
      </div>
      {props.values?.map((radioValue: string) => {
        return (
          <label className='d-flex flex-left mb-2 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                name={props.variable}
                type='radio'
                value={radioValue}
                onChange={(e: any) => {
                  formik.setFieldValue(props.variable || '', e.target.value)
                  setValue(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
              />
            </span>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <h5 className='ms-4 text-hover-primary'>{radioValue}</h5>
              </span>
            </span>
          </label>
        )
      })}
    </div>
  )
}

export function CheckBox(props: Prop) {
  const formik = useFormikContext<TermsheetType>()
  useEffect(() => {
    if (formik.values[`${props.variable}`].includes('CCPS')) {
      formik.setFieldValue('isSecurityName', true)
    } else {
      formik.setFieldValue('isSecurityName', false)
    }
  }, [])
  return (
    <div className='row align-items-center pb-4'>
      <div className='col-6 col-md-6 pb-4 d-flex'>
        <h4 className={`${props.required === 'true' ? 'required' : ''} pt-3`}>{props.fieldName}</h4>
        {props.toolTip && (
          <>
            <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id={props.fieldName} className=''>
              {props.toolTip.map((tool: any) => (
                <>
                  <h1 className='bold text-white'>{tool.title}</h1>
                  <div>{tool.description}</div>
                </>
              ))}
            </Tooltip>
          </>
        )}
      </div>
      {props.values?.map((chekboxValue: string, index: number) => {
        return (
          <label className='d-flex flex-left mb-2 cursor-pointer'>
            <span className='form-check form-check-custom form-check-solid'>
              <Field
                className='form-check-input'
                name={props.variable}
                type='checkbox'
                value={chekboxValue}
                onClick={(e: any) => {
                  if (
                    formik.values[`${props.variable}`].includes('CCPS') &&
                    e.target.value === 'CCPS'
                  ) {
                    formik.values['securityType'] = ''
                    formik.values['isSecurityName'] = false
                  } else if (
                    !formik.values[`${props.variable}`].includes('CCPS') &&
                    e.target.value === 'CCPS'
                  ) {
                    formik.values['isSecurityName'] = true
                  } else if (
                    !formik.values[`${props.variable}`].includes('CCPS') &&
                    e.target.value !== 'CCPS'
                  ) {
                    formik.values['securityType'] = ''
                    formik.values['isSecurityName'] = false
                  }
                  console.log(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
              />
            </span>
            <div className='d-flex justify-content-between w-50'>
              <span className='d-flex align-items-center me-2'>
                <span className='d-flex flex-column'>
                  <h5 className='ms-4 text-hover-primary'>{chekboxValue}</h5>
                </span>
              </span>
              {/* {(formik.values[`${props.variable}`].includes('Equity') && index === 0) && <span>
              <Field 
              type="number"
              name={`${props.variable}nominalEquity`}
              className='form-control form-control-md form-control-solid'
              placeholder = "Enter Nominal Equity"
              max = "100"
              />
            </span>} */}
            </div>
          </label>
        )
      })}
      <div className='text-danger mt-2'>
        <ErrorMessage name={props.variable || ''} />
      </div>
    </div>
  )
}

export function Toggle(props: Prop) {
  return (
    <span className='d-flex mb-4'>
      <label className={`form-label ${props.required === 'true' ? 'required' : ''} pt-3`}>
        {props.fieldName}
      </label>
      {props.toolTip && (
        <>
          <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
          </a>
          <Tooltip id={props.fieldName} className=''>
            {props.toolTip.map((tool: any) => (
              <>
                <h1 className='bold text-white'>{tool.title}</h1>
                <div>{tool.description}</div>
              </>
            ))}
          </Tooltip>
        </>
      )}
      <div className='form-check form-switch form-check-custom form-check-solid ps-12'>
        <Field
          className='form-check-input h-20px w-40px'
          type='checkbox'
          name={props.variable}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
        />
      </div>
    </span>
  )
}

export function Dropdown(props: Prop) {
  const formik = useFormikContext<TermsheetType>()
  const dropdownItems: MultiSelectOptionType[] = []
  const [values, setValues] = useState<any>({
    value: formik.values[props.variable || ''],
    label:
      props.variable === 'currency'
        ? formik.values[props.variable || ''] === 'en-IN'
          ? 'India - INR'
          : 'United States-USD'
        : formik.values[props.variable || ''],
  })

  useEffect(() => {
    setValues({
      value: formik.values[props.variable || ''],
      label:
        props.variable === 'currency'
          ? formik.values[props.variable || ''] === 'en-IN'
            ? 'India - INR'
            : 'United States-USD'
          : formik.values[props.variable || ''],
    })
  }, [formik.values[props.variable || '']])

  props.values?.forEach((element: Dropdown) =>
    !dropdownItems.find((invObj) => invObj.label === element.label)
      ? dropdownItems.push({
          value: element.value,
          label: element.label,
        })
      : {}
  )
  return (
    <div className='row align-items-baseline pb-4'>
      <div className='col-6 col-md-4 d-flex'>
        <label className={`form-label ${props.required === 'true' ? 'required' : ''} pt-3`}>
          {props.fieldName}
        </label>
        {props.toolTip && (
          <>
            <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id={props.fieldName} className=''>
              {props.toolTip.map((tool: any) => (
                <>
                  <h1 className='bold text-white'>{tool.title}</h1>
                  <div>{tool.description}</div>
                </>
              ))}
            </Tooltip>
          </>
        )}
      </div>
      <div className='col-5 '>
        <Select
          className='w-50'
          options={dropdownItems}
          name={props.variable}
          menuPlacement='auto'
          menuPortalTarget={document.body}
          onChange={(e: any) => {
            setValues(e)
            formik.setFieldValue(props.variable || '', e.value)
          }}
          value={values}
          styles={selectCustomStyles}
        />
        {formik.values['keyManInsurance'] === 'at least INR ' &&
          props.variable === 'keyManInsurance' && (
            <div className='w-50 mt-5'>
              <Field
                type='number'
                name={`${props.variable}additional`}
                className='form-control form-control-md form-control-solid'
                placeholder='Enter Details'
                onWheel={(e: any) => e.target.blur()}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') e.preventDefault()
                }}
              />
            </div>
          )}
        <div className='text-danger mt-2'>
          <ErrorMessage name={props.variable || ''} />
        </div>
      </div>
      {props.aiInits &&
        props.aiInits[props.variable!] !== '' &&
        props.aiInits[props.variable!] !== 0 &&
        props.aiInits[props.variable!] !== '0.00' &&
        props.aiInits[props.variable!] !== undefined && (
          <div className='col-1'>
            <span>{getAiIcon()}</span>
          </div>
        )}
    </div>
  )
}

export function DatePicker(props: Prop) {
  const formik = useFormikContext<TermsheetType>()
  const [fieldDate, setFieldDate] = useState('')
  useEffect(() => {
    const date = formik.values[props.variable!].split('-')
    if (date[0].length === 4) {
      setFieldDate(formik.values[props.variable!])
    } else {
      setFieldDate(`${date[2]}-${date[1]}-${date[0]}`)
    }
  }, [formik.values, props.variable])

  let date = new Date()
  const minDate =
    props.min?.toString().toLowerCase() === 'now'
      ? date.toISOString().split('T')[0]
      : props.min != '' && getDate(props.min || '')
  const maxDate =
    props.max?.toString().toLowerCase() === 'now'
      ? date.toISOString().split('T')[0]
      : props.max != '' && getDate(props.max || '')
  return (
    <div className='row align-items-baseline pb-4'>
      <div className='col-6 col-md-4 pb-4 d-flex'>
        <label className={`form-label ${props.required === 'true' ? 'required' : ''} pt-3`}>
          {props.fieldName}
        </label>
        {props.toolTip && (
          <>
            <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id={props.fieldName} className=''>
              {props.toolTip.map((tool: any) => (
                <>
                  <h1 className='bold text-white'>{tool.title}</h1>
                  <div>{tool.description}</div>
                </>
              ))}
            </Tooltip>
          </>
        )}
      </div>
      <div className='col-5 '>
        {maxDate && minDate && (
          <Field
            type='date'
            className='form-control custom-input fw-bold shadow-lg h-38px'
            placeholder='dd-mm-yyyy'
            name={props.variable}
            min={minDate}
            max={maxDate}
            value={fieldDate}
            onChange={(e: any) => {
              setFieldDate(e.target.value)
              formik.setFieldValue(props.variable!, dateToFieldDate(e.target.value))
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        )}
        {maxDate && !minDate && (
          <Field
            type='date'
            className='form-control custom-input fw-bold shadow-lg h-38px'
            placeholder='dd-mm-yyyy'
            name={props.variable}
            min={minDate}
            max={maxDate}
            value={fieldDate}
            onChange={(e: any) => {
              setFieldDate(e.target.value)
              formik.setFieldValue(props.variable!, dateToFieldDate(e.target.value))
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        )}
        {!maxDate && minDate && (
          <Field
            type='date'
            className='form-control custom-input fw-bold shadow-lg h-38px'
            placeholder='dd-mm-yyyy'
            name={props.variable}
            min={minDate}
            max={maxDate}
            value={fieldDate}
            onChange={(e: any) => {
              setFieldDate(e.target.value)
              formik.setFieldValue(props.variable!, dateToFieldDate(e.target.value))
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        )}
        {!maxDate && !minDate && (
          <Field
            type='date'
            className='form-control custom-input fw-bold shadow-lg h-38px'
            placeholder='dd-mm-yyyy'
            name={props.variable}
            min={minDate}
            max={maxDate}
            value={fieldDate}
            onChange={(e: any) => {
              setFieldDate(e.target.value)
              formik.setFieldValue(props.variable!, dateToFieldDate(e.target.value))
            }}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        )}

        <div className='text-danger mt-2'>
          <ErrorMessage name={props.variable || ''} />
        </div>
      </div>
      {props.aiInits &&
        props.aiInits[props.variable!] !== '' &&
        props.aiInits[props.variable!] !== 0 &&
        props.aiInits[props.variable!] !== '0.00' &&
        props.aiInits[props.variable!] !== undefined && (
          <div className='col-1'>
            <span>{getAiIcon()}</span>
          </div>
        )}
    </div>
  )
}

export function Button(props: Prop) {
  return (
    <div className='pb-4'>
      <button
        type='button'
        className='btn border border-primary fs-base fw-bold pb-1 col-4'
        onClick={() => {}}
      >
        <h5 className='text-primary pb-1'>{props.fieldName}</h5>
      </button>
    </div>
  )
}

export function CompanySearch(props: Prop) {
  const [companyOptions, setCompanyOptions] = useState<any>([])
  const [searchResults, setSearchResults] = useState<SearchCompanyResultType[]>([])
  const [showResults, setShowResults] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [isQueryEnabled, setQueryEnabled] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)

  const searchCompany = useSearchQuery<SearchQuery, Error>(
    graphqlRequestClient,
    { input: { name: companyName } },
    {
      enabled: isQueryEnabled,
      onSuccess(data) {
        handleSearchResults(data.searchCompany)
        setQueryEnabled(false)
      },
    }
  )

  useEffect(() => {
    const getData = setTimeout(() => {
      if (isQueryEnabled) searchCompany.refetch()
    }, 2000)
    return () => clearTimeout(getData)
  }, [companyName])

  function handleSearchResults(data: SearchCompanyResultType[]) {
    setSearchResults(data)
    setShowResults(true)
    const options = data.map((company) => {
      return {
        key: company.name,
        value: company.name,
        label: company.name,
        companyName: company.name,
        companyCin: company.cin,
      }
    })
    setCompanyOptions(options)
  }

  return (
    <div className='row align-items-baseline pb-4'>
      <div className='col-6 col-md-4 pb-4'>
        <label className={`form-label ${props.required === 'true' ? 'required' : ''} mb-3`}>
          {props.fieldName}
        </label>
      </div>
      <div className='col-6 col-md-8'>
        <Select
          className='col-6'
          styles={selectCustomStyles}
          inputId='aria-company-input'
          name='aria-company'
          options={companyOptions}
          isClearable={true}
          onInputChange={(e) => {
            if (e.length >= 3) {
              setCompanyName(e)
              setQueryEnabled(true)
            }
          }}
          onChange={(e: any) => {
            const index = companyOptions.findIndex(
              (company: any) => company.companyName == e?.companyName
            )
            if (index > -1) {
              setSelectedItem(companyOptions[index])
            }
            setShowResults(true)
          }}
        ></Select>
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyName' />
        </div>
      </div>
    </div>
  )
}

export function DisabledTextfield(props: Prop) {
  const formik = useFormikContext<TermsheetType>()
  return (
    <div className='row align-items-baseline pb-4'>
      <div className='col-6 col-md-4 pb-4 d-flex'>
        <label className={`form-label ${props.required === 'true' ? 'required' : ''} pt-3`}>
          {props.fieldName}
        </label>
        {props.toolTip && (
          <>
            <a data-tooltip-id={props.fieldName} className='ms-2 mt-2'>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen045.svg' />
            </a>
            <Tooltip id={props.fieldName} className=''>
              {props.toolTip.map((tool: any) => (
                <>
                  <h1 className='bold text-white'>{tool.title}</h1>
                  <div>{tool.description}</div>
                </>
              ))}
            </Tooltip>
          </>
        )}
      </div>
      <div className='col-6 col-md-8'>
        <Field
          type={props.type || 'text'}
          name={props.variable}
          className='form-control custom-input fw-bold shadow-lg h-38px'
          disabled={true}
          placeholder={!props.isBuilder ? `Enter ${props.fieldName}` : ''}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') e.preventDefault()
          }}
          value={Intl.NumberFormat(`${formik?.values?.currency || 'en-IN'}`).format(
            formik.values[props.variable || '']
          )}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name={props.variable || ''} />
        </div>
      </div>
    </div>
  )
}
