/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { AsideMenuItem } from './AsideMenuItem'
import { useCanUserAccess } from '../../../../app/pages/store/userRoleGrants'

export function AsideMenuMain() {
  const [header, setHeader] = useState('')

  useEffect(() => {
    setHeader(window.location.pathname)
  }, [])

  const routes = ['/adduser', '/addInvestor', '/addAccount', '/noAccess']
  const canDashboard = useCanUserAccess('read', 'dashboard')
  const canPortfolio = useCanUserAccess('read', 'portfolio')
  const canWatchlist = useCanUserAccess('read', 'watchlist')
  const canDeals = useCanUserAccess('read', 'documentAssembler')
  const canFundMetrics = useCanUserAccess('read', 'fundMetrics')
  const canPrivateCaptables = useCanUserAccess('read', 'myCaptables')
  const canExitEnabler = useCanUserAccess('read', 'exitEnabler')
  const canExitEnablerAdmin = useCanUserAccess('read', 'exitEnablerAdmin')
  return (
    <>
      {routes.includes(header) ? (
        <></>
      ) : (
        <>
          {
            <AsideMenuItem
              title=''
              icon='media/logos/favicon.png'
              fontIcon='bi-app-indicator'
              to=''
              isHeader={true}
              disabled={true}
            />
          }
          {<span className='pb-4'></span>}
          {canDashboard && (
            <AsideMenuItem
              to='/dashboard'
              title='Dashboard'
              icon='/media/icons/duotune/layouts/lay001.svg'
              fontIcon='bi-app-indicator'
            />
          )}
          {canPortfolio && (
            <AsideMenuItem
              to='/portfolio'
              title='Portfolios'
              icon='/media/icons/duotune/graphs/gra010.svg'
              fontIcon='bi-app-indicator'
            />
          )}
          {canWatchlist && (
            <AsideMenuItem
              to='/watchlist'
              title='Watchlist'
              icon='/media/icons/duotune/general/gen004.svg'
              fontIcon='bi-app-indicator'
            />
          )}
          {canDeals && (
            <AsideMenuItem
              to='/deals'
              title='Deals'
              icon='/media/icons/duotune/finance/fin010.svg'
              fontIcon='bi-app-indicator'
            />
          )}
          {canFundMetrics && (
            <AsideMenuItem
              to='/fundmetrics'
              title='Metrics'
              icon='/media/icons/duotune/abstract/abs027.svg'
              fontIcon='bi-app-indicator'
            />
          )}
          {(canExitEnabler || canExitEnablerAdmin) && (
            <AsideMenuItem
              to='/exitEnabler'
              title='Exit Enabler'
              icon='/media/icons/duotune/finance/fin006.svg'
              fontIcon='bi-app-indicator'
            />
          )}
          {canPrivateCaptables && (
            <AsideMenuItem
              to='/myCaptables'
              title='My Captables'
              icon='/media/icons/duotune/layouts/lay009.svg'
              fontIcon='bi-app-indicator'
            />
          )}
        </>
      )}
    </>
  )
}
