import { useMemo } from 'react'
import { getUserInvestors, getUserRoles } from '../../../utils/LocalStorageUtils'
import { InvestorType } from '../accounts/components/AddInvestor'

type actions = 'create' | 'read' | 'update' | 'delete' | '*'

type resources =
  | 'documentAssembler'
  | 'portfolio'
  | 'dashboard'
  | 'watchlist'
  | 'fundMetrics'
  | 'company'
  | 'indirectCompanies'
  | 'adduser'
  | 'addInvestor'
  | 'addAccount'
  | 'companyAccess'
  | 'exitEnabler'
  | 'exitEnablerAdmin'
  | 'myCaptables'
  | '*'

export interface Role {
  resourceName?: string
  resource: resources
  actionName?: string
  action: actions
  attributes: string[]
}

export interface UserInvestor {
  investorName: string
  type: InvestorType
  investorId: number
}

const exclusionRoles = ['companyAccess', 'exitEnabler', 'exitEnablerAdmin']

const checkResourceInExclusionList = (resources: string[]) => {
  return resources.some((res) => exclusionRoles.includes(res))
}

export function useCanUserAccess(action: actions | actions[], resource: resources | resources[]) {
  const data = getUserRoles('userRoles')
  const investorTypes = getUserInvestors('userInvestors')

  const canUserAccess = useMemo(() => {
    const userRoles = data || []
    const newPermissions = new Set<Role>()
    const higherPermissions: actions[] = ['create', 'delete', 'update']

    userRoles?.forEach((role) => {
      if (higherPermissions.includes(role.action)) {
        newPermissions.add({ ...role, action: 'read' })
      }
    })

    userRoles.concat(...Array.from(newPermissions))

    if (checkResourceInExclusionList(Array.isArray(resource) ? resource : [resource])) {
      const canAccessResource = userRoles.filter((res) => resource.includes(res.resource))
      if (canAccessResource.length === 0) return false
      const canPerformAction = canAccessResource.find(
        (res) => action.includes(res.action) || res.action === '*'
      )
      return canPerformAction
    }

    const isAdmin = userRoles?.some((role) => role.resource === '*' && role.action === '*')

    if (isAdmin) return true

    let canAccessResource = userRoles?.some(
      (role) => role.resource === resource || role.resource === '*'
    )

    let canPerformAction = userRoles?.some(
      (role) => role.action === action && (resource === role.resource || role.resource === '*')
    )

    if (canAccessResource && !canPerformAction) {
      canPerformAction = userRoles?.some(
        (role) => role.resource === resource && role.action === '*'
      )
    }

    if (canPerformAction && !canAccessResource) {
      canAccessResource = userRoles?.some((role) => role.resource === '*' && role.action === action)
    }

    return canAccessResource && canPerformAction
  }, [data, action, resource])
  if (resource === 'fundMetrics') {
    if (
      investorTypes.every(
        (investorType) => investorType.type.toString() === InvestorType.Angel.toString()
      )
    )
      return false
  }
  return canUserAccess
}
